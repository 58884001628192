@tailwind base;

@tailwind components;

@tailwind utilities;

.orderStatus--A_1,
.orderStatus--A_5 {
  background-color: rgb(252 211 77);
}
.orderStatus--A_2 {
  background-color: rgb(190 242 100);
}
.orderStatus--A_3 {
  background-color: rgb(161 161 170);
  color: white;
}
.orderStatus--A_4 {
  background-color: rgb(248 113 113);
  color: white;
}

.paymentStatus--WAITING_PAYMENT {
  background-color: rgb(252 211 77);
}
.paymentStatus--PAYED {
  background-color: rgb(190 242 100);
}
.paymentStatus--FAILED {
  background-color: rgb(248 113 113);
  color: white;
}

.grid-columns-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-columns-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-columns-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-columns-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-columns-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-columns-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}


.grid-columns-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}


.grid-columns-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

